import React, { useEffect, useState } from 'react';
import { makeStyles, Container, Grid, Button } from '@material-ui/core';
import DetailSummary from './components/DetailSummary';
import UserAddressDetails from './components/address/Index';
import PaymentOption from './components/payment-option/PaymentOption';
import Steps from './components/Steps';
import ReviewPay from './components/review-pay/ReviewPay';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';

const useStyles = makeStyles(theme => ({
  userJourneyWrapper: {
    backgroundColor: '#f5f5f5',
    '& figure': {
      margin: '0',
      '& img': {
        maxWidth: '100%'
      }
    },
    display: 'block',
    '& .container': {
      padding: theme.spacing(2, 1.25),
      maxWidth: '1170px',
      ['@media(min-width: 1370px)']: {
        maxWidth: '1280px'
      }
    },
    '& .mainButton': {
      backgroundColor: '#3ba5e0'
    },
    '& .main-heading': {
      color: '#30343e',
      fontSize: '22px',
      fontWeight: '500'
    },
    '& .sub-heading': {
      fontSize: '16px',
      fontWeight: '500',
      color: '#30343e',
      position: 'relative',
      marginBottom: '10px'
    },
    '& a': {
      textDecoration: 'none',
      color: '#30343e'
    },
    '& a:hover': {
      textDecoration: 'underline'
    },
    '& .box-panel': {
      borderRadius: '5px',
      padding: '10px',
      border: '1px solid #eef9ff',
      background: '#fff',
      '& .MuiRadio-root': {
        padding: '0'
      },
      '& .Mui-checked': {
        color: '#3ba5e0'
      }
    },
    '& .detail-block': {
      display: 'flex',
      alignItems: 'flex-start',
      '& .detail-panel': {
        paddingLeft: '10px'
      },
      '& .contact-mail-phone': {
        marginTop: '5px',
        lineHeight: '2'
      }
    },
    '& .review-section': {
      '& .sub-heading': {
        margin: '0 0 10px'
      }
    },
    [theme.breakpoints.down('sm')]: {
      '& .left-column, & .right-column': {
        padding: '0'
      },
      '& .msite-container': {
        paddingLeft: '15px',
        paddingRight: '15px'
      },
      '& .mainButton': {
        marginBottom: '20px'
      }
    }
  }
}));

export default function LoginJourney() {
  const classes = useStyles();
  const history = useHistory();
  const { payMethod } = useSelector(state => state.basicDetail);
  const [open, setOpen] = React.useState(false);
  const [completedStep, setCompletedStep] = useState(0);
  const [step, setStep] = useState(1);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [rcData, setRcData] = useState('');

  const handleStepData = async e => {
    if (step == 3) {
      e.preventDefault();
      await axios
        .get(
          `/drapi/buy-report?payment_method=${payMethod}&rc_number=${rcData}&report_type=platinum&source=history_web&api_token=`
        )
        .then(res => {
          if (res.data.data.code === 'success') {
            setButtonDisable(true);
            const htmlValue = res.data.data.data;
            let div = document.createElement('div');
            div.innerHTML = htmlValue;
            document.body.appendChild(div );
            document.frmPaymentGtw_PAYTM.submit();
          }
        });
    } else {
      setStep(prev => prev + 1);
      setCompletedStep(prev => prev + 1);
    }
  };
  const handlePaymentStep = () => {
    if (step > 1) {
      setStep(prev => prev - 1);
    }
  };
  const handleParticularStep = value => {
    setStep(value);
  };
  const handleModalOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (sessionStorage.getItem('rc_no')) {
      setRcData(sessionStorage.getItem('rc_no'));
    }
  }, [rcData, history, buttonDisable]);

  return (
      <div className={classes.userJourneyWrapper}>
      <Container maxWidth="lg" className="container">
        <Grid container spacing={2}>
          <Grid item sm={8} xs={12} className="left-column">
            {
              <Steps
                stateIndex={step - 1}
                completedStep={completedStep}
                handlePaymentStep={handlePaymentStep}
              />
            }
            {step === 1 && (
              <UserAddressDetails
                handleModalOpen={handleModalOpen}
                stepCount={step}
              />
            )}
            {step === 2 && <PaymentOption />}
            {step === 3 && (
              <ReviewPay
                handlePaymentStep={handlePaymentStep}
                handleParticularStep={handleParticularStep}
              />
            )}
          </Grid>
          <Grid item sm={4} xs={12} className="right-column">
            <DetailSummary
              handleStep={handleStepData}
              buttonDisable={buttonDisable}
            />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
