import axios from 'axios';
const fetchCall = async url => {
  return await axios
    .get(url, {
      headers: {
        source: 'history_web',
        token: process.env.API_TOKEN
      }
    })
    .then(res => {
      return res.data;
    });
};
const postData = async (url, body) => {
  return await axios
    .post(url, body, {
      headers: {
        source: 'history_web',
        token: process.env.API_TOKEN
      }
    })
    .then(res => {
      return res.data;
    });
};

export { postData, fetchCall };
