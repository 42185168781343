import React, { useEffect, useState } from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import { useDispatch, useSelector } from 'react-redux';
import { updateValue } from '@redux/reducers/basicDetailSlice';
import { fetchCall } from '@helper/restApiHandler';

const useStyles = makeStyles(theme => ({
  paymentModeWrapper: {
    '& .sub-heading': {
      margin: '0',
    },
    '& .details': {
      fontSize: '18px',
      '& .text-light': {
        color: '#6f727b',
        fontSize: '14px',
      },
    },
    '& .payment-icon': {
      textAlign: 'center',
      fontSize: '30px',
    },
    '& .payment-mode-wrapper': {
      marginTop: '20px',
    },
    '& .payment-mode-wrapper:first-child': {
      marginTop: '0',
    },
    '& .d-history-upi': {
      fontSize: '16px',
    },
    '& .d-history-card': {
      position: 'relative',
    },
    '& .d-history-card:after': {
      content: '"card"',
      position: 'absolute',
      left: '0',
      top: '0',
      fontSize: '12px',
      color: '#fff',
      right: '0',
      bottom: '0',
      margin: 'auto',
      height: '15px',
      textTransform: 'uppercase',
    },
    [theme.breakpoints.down('sm')]: {
      '& .payment-icon': {
        fontSize: '16px',
      },
      '& .d-history-card:after': {
        fontSize: '8px',
        height: '10px',
      },
      '& .d-history-upi': {
        fontSize: '14px',
      },
      '& .d-history-globe': {
        fontSize: '26px',
      },
      '& .d-history-card': {
        fontSize: '26px',
      },
    },
  },
}));

export default function PaymentMode() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const payMethod = useSelector(state => state.basicDetail.payMethod); 
  const [paymentMethods, setPaymentMethods] = useState([]);

  useEffect(() => {
    const fetchPaymentOptions = async () => {
      const response = await fetchCall('drapi/payment-options');
      if (response.data.code =='success') {
        setPaymentMethods(response.data.data);
      } 
    };
  
    fetchPaymentOptions();
  }, []);
  
  const handleRadioChange = event => {
    const selectedValue = event.target.value;
    dispatch(updateValue(selectedValue)); 
  };

  return (
    <div className={classes.paymentModeWrapper}>
      <div className="msite-container">
        <h2 className="main-heading">Select Payment Options to Pay</h2>
      </div>
      <div className="box-panel">
        <div className="msite-container">
          {paymentMethods.length > 0 ? (
            paymentMethods.map((method, index) => (
              <Grid container spacing={2} className="payment-mode-wrapper" key={index}>
                <Grid item xs={10} className="detail-block">
                  <Radio
                    color="primary"
                    value={method.value}
                    name="paymentMode"
                    id={method.value}
                    inputProps={{ 'aria-label': method.name }}
                    onChange={handleRadioChange}
                    checked={payMethod === method.value}
                  />
                  <div className="detail-panel">
                    <label htmlFor={method.value} className="details">
                      <div>{method.name}</div>
                    </label>
                  </div>
                </Grid>
                <Grid item xs={2} className="payment-icon">
                  <i className={`d-history-${method.value.toLowerCase()}`}></i>
                </Grid>
              </Grid>
            ))
          ) : (
            <div>No payment methods available.</div>
          )}
        </div>
      </div>
    </div>
  );
}