import React from 'react';
import { Container } from '@material-ui/core';
import './static-pages.scss';
export default function PrivacyPolicy() {
  return (
    <div id="privacy-policy" className="static-page-wrapper">
      <Container className="container">
        <h1 className="section-heading">Privacy Policy</h1>
        <section className="section-wrapper">
          <p>
            <strong>
              Last updated on: 4<sup>th</sup> December 2024
            </strong>
          </p>
          <p>
            Welcome to <a href="https://vehiclehistory.in">vehiclehistory.in</a>
            . Vehicle History is a platform that provides users with the overall
            information about a vehicle's history. Through History, the users
            can check the history of any car, bike or scooter by generating the
            vehicle history certificate. The certificate generated by Vehicle
            History helps you check any car's, bike's or scooter's history
            online.
          </p>
          <p>
            This Privacy Policy relates solely to the information collection and
            use practices of Our website located at{' '}
            <a href="https://www.vehiclehistory.in">
              https://www.vehiclehistory.in
            </a>{' '}
            or the mobile site or mobile application (individually and
            collectively as <b>"Website"</b>). Through the Website we provide
            access to users to the products and services available therein
            (collectively referred to as <b>"Services"</b>).
          </p>

          <p>
            The Website is owned and operated by Droom Technology Limited
            (formerly known as Droom Technology Private Limited), a company
            incorporated under the Companies Act, 2013 with its registered
            office at 90/31B, First Floor, Malviya Nagar, New Delhi 100017, New
            Delhi, India (hereinafter referred to as <b>"Droom"/"Company"</b>). 
          </p>

          <p>
            Our Privacy Policy is incorporated as part of the Website Terms of
            Use. Please read this Privacy Policy and Our Terms of Use carefully
            before using Our Services. By using the Website's Services, You
            agree to the provisions of this Privacy Policy. If You do not want
            to be bound by Our Privacy Policy, please do not use Our Services.
          </p>

          <p>
            The term <b>“We”/ “Us” / “Our”</b> used in this document refer to
            Droom and "You" / "Your" / "Yourself" refer to the Users, who visit
            or access or use or avail any service (collectively “usage”).
          </p>

          <p>
            <strong>NOTE:</strong>
            <b>
              THIS PRIVACY POLICY IS AN ELECTRONIC RECORD IN THE FORM OF
              ELECTRONIC CONTRACT IN TERMS OF INFORMATION TECHNOLOGY ACT, 2000
              AND RULES MADE THEREUNDER (AS AMENDED FROM TIME TO TIME) AND DOES
              NOT REQUIRE ANY PHYSICAL SIGNATURE OR SEAL.
            </b>
          </p>

          <p>
            Our privacy policy is subject to change at any time without notice.
            To make sure You are aware of any changes, please review this policy
            periodically.
          </p>

          <p>
            By visiting this Website and/ or downloading the History mobile
            application You agree to be bound by the terms and conditions of
            this Privacy Policy. If You do not agree please do not download, use
            or access Our Website. By mere use of the Website, You expressly
            consent to Our use and disclosure of Your personal information in
            accordance with this Privacy Policy.
          </p>
        </section>
        <section className="section-wrapper">
          <ul style={{ listStyle: 'none', paddingLeft: '30px' }}>
            <li>
              <strong>
                1. COLLECTION OF PERSONALLY IDENTIFIABLE INFORMATION AND OTHER
                INFORMATION
              </strong>
              <p> </p>

              <p>
                This Privacy Policy extends to both, users who visit the Website
                but do not transact business on the Website and as well as users
                who are registered on the Website and transact business on the
                Website. When you use our Website, we collect and store your
                personal information which is provided by you from time to time.
                We may also share your Personal or non-Personal information
                where it is required and share the same with third Parties for
                providing you the product or service and you provide us, your
                unconditional consent to do the same.
              </p>
              <p>
                Our primary goal in doing so is to provide You a safe,
                efficient, smooth and customized experience. This allows Us to
                provide services and features that most likely meet Your needs,
                and to customize Our Website to make Your experience safer and
                easier. More importantly, while doing so We collect personal
                information from You that We consider necessary for achieving
                this purpose.
              </p>

              <p>
                In general, You can browse the Website without telling Us who
                You are or revealing any personal information about Yourself.
                Once You give Us Your personal information, You are not
                anonymous to Us. Where possible, We indicate which fields are
                mandatory/ required and which fields are optional. You always
                have the option to not provide information by choosing not to
                use a particular service or feature on the Website. We may
                automatically track certain information about You based upon
                Your behaviour on Our Website. We use this information to do
                internal research on Our Users' demographics, interests, and
                behaviour to better understand, protect and serve Our Users.
                This information is compiled and analysed on an aggregated
                basis. This information may include, but is not limited to, the
                URL that You just came from (whether this URL is on Our Website
                or not), which URL You next go to (whether this URL is on Our
                Website or not), Your computer browser information, and Your IP
                address.
              </p>

              <p>
                By using the Website or the Services, you consent to collection,
                storage, and use of the personal information you provide
                (including any changes thereto as provided by you or your
                representatives, nominees, assigns) for any of the services that
                we offer. Users of the Website agree and acknowledge that they
                are being made fully aware by the Website / Droom about the
                manner and the purposes for which, the information so shared
                /disclosed by them will be used.
              </p>

              <p>
                A "cookie" is a small piece of information stored by a Web
                server on a Web browser so it can be later read back from that
                browser. The acceptance of cookies is not a requirement for
                visiting the Website. However, We would like to point out that
                some functionality on the Website is only possible with the
                activation of cookies. Cookies are tiny text files which
                identify Your computer to Our server as a unique User when You
                visit certain pages on the Website and they are stored by Your
                Internet browser on Your computer's hard drive. Usually, cookies
                contain two pieces of information: a site name and a unique User
                ID. Cookies cannot be used alone to identify You, although they
                may be used to identify Your IP address. We use data collection
                devices such as "cookies" on certain pages of the Website to
                help analyse Our web page flow, measure promotional
                effectiveness, and promote trust and safety.Droom respects the privacy of users and processes personal data in accordance with the Privacy Policy .
              </p>

              <p>
                We also use cookies to allow You to enter Your password less
                frequently during a session. Cookies can also help Us provide
                information that is targeted to Your interests. Most cookies are
                "session cookies," meaning that they are automatically deleted
                from Your hard drive at the end of a session. You are always
                free to decline Our cookies if Your browser permits, although in
                that case You may not be able to use certain features on the
                Website and You may be required to re-enter Your password more
                frequently during a session. Additionally, You may encounter
                "cookies" or other similar devices on certain pages of the
                Website that are placed by third parties. We do not control the
                use of cookies by third parties. If You choose to buy/ transact
                on the Website, We collect information about Your buying/
                transaction behaviour. When You transact with Us, We also
                collect some additional information, such as a billing address,
                a credit/ debit card number and a credit / debit card expiration
                date and/ or other payment instrument details and tracking
                information from cheques or money orders.
              </p>

              <p>
                If You choose to post messages on Our message boards, chat rooms
                or other message areas or leave feedback, We will collect that
                information You provide to Us. We retain this information as
                necessary to resolve disputes, provide customer support and
                troubleshoot problems as permitted by law. Further, we may share
                the feedback or User requirements through a lead generation form
                which we may share with third parties or the Company’s internal
                team.
              </p>

              <p>
                If You send Us personal correspondence, such as emails or
                letters, or if other Users or third parties send Us
                correspondence about Your activities or postings on the Website,
                We may collect such information into a file specific to You. We
                collect personally identifiable information (email address,
                name, phone number, etc.) from You when You set up a free
                account with Us. While you can browse some sections of our
                Website without being a registered member, certain activities
                (such as placing an order) do require registration. We do use
                Your contact information to send You correspondence based on
                Your interests.
              </p>

              <p>
                By sharing / posting such personally identifiable information on
                the Website, you hereby unconditionally and without any riders,
                agree and acknowledge that Droom may store and at time, if
                required, share the personal information so provided by You with
                other Users for the purposes of providing the Services on this
                Website.
              </p>

              <p>
                We will only keep Your information for as long as We are either
                required to by law or as is relevant for the purposes for which
                it was collected.
              </p>
            </li>
            <li>
              <strong>
                2. USE OF DEMOGRAPHIC / PROFILE DATA / YOUR INFORMATION
              </strong>
              <p> </p>
              <p>
                We use personal information to provide the services You request.
                To the extent, We use Your personal information to market to
                You, We will provide You the ability to opt-out of such uses. We
                use Your personal information to resolve disputes; troubleshoot
                problems; help promote a safe service; measure consumer interest
                in Our products and services, inform You about online and
                offline services, and updates; customize Your experience; detect
                and protect Us against error, fraud and other criminal activity;
                enforce Our Terms of Use; and as otherwise described to You at
                the time of collection.
              </p>

              <p>
                In Our efforts to continually improve Our product and service
                offerings, We collect and analyse demographic and profile data
                about Our Users' activity on Our Website.
              </p>

              <p>
                We identify and use Your IP address to help diagnose problems
                with Our server, and to administer Our Website. Your IP address
                is also used to help identify You and to gather broad
                demographic information.
              </p>

              <p>
                We may occasionally ask You to complete optional online surveys.
                These surveys may ask You for contact information and
                demographic information (like zip code, age, or income level).
                We use this data to tailor Your experience at Our Website,
                providing You with content that We think You might be interested
                in and to display content according to Your preferences.
              </p>
            </li>
            <li>
              <strong>3. SHARING OF PERSONAL INFORMATION</strong>
              <p> </p>
              <p>
                We may share personal information with Our other corporate
                entities and affiliates to help detect and prevent identity
                theft, fraud and other potentially illegal acts; correlate
                related or multiple accounts to prevent abuse of our services;
                and to facilitate joint or co-branded services that You request
                where such services are provided by more than one corporate
                entity. Those entities and affiliates may not market to You as a
                result of such sharing unless You explicitly opt-in.
              </p>

              <p>
                Access to personal information will also be given to those
                persons authorized by Droom and third parties engaged by Droom
                for administrative activities. We further clarify that by using
                our Website and / or Services available on the Website, We may
                utilize/disclose the details shared by Our users, without
                prejudice to any of their rights, for commercial or
                non-commercial purpose which we in our sole discretion consider
                necessary for Our business purposes or otherwise. While We
                ensure to the best of our capability that such third parties
                also hold your information in strictest confidence, We do not
                have 100% control over them and, therefore, We cannot guarantee
                complete privacy.
              </p>

              <p>
                Further, the comments and photographs of Our Users may be
                publicized as testimonials for promotion of the Website and/ or
                the Services.
              </p>

              <p>
                We and Our affiliates will share / sell some or all Your
                personal information with another business entity should We (or
                Our assets) plan to merge with, or be acquired by that business
                entity, or re-organization, amalgamation, restructuring of
                business. Should such a transaction occur the other business
                entity (or the new combined entity) will be required to follow
                this Privacy Policy with respect to Your personal information.
              </p>
            </li>

            <li>
              <strong>4. PERSONALISED SERVICES </strong>
              <p> </p>
              <p>
                From time to time We may add or enhance services available on
                the Website. To the extent these services are provided, and used
                by You, We will use the information You provide to facilitate
                the service requested. For example, if You email Us with a
                question, We will use Your email address, name, nature of the
                question, etc. to respond to Your question. We may also store
                such information to assist Us in making the site the better and
                easier to use.
              </p>
            </li>

            <li>
              <strong>5. COMPLIANCE WITH LAW </strong>
              <p> </p>
              <p>
                In addition to the circumstances described above, We may
                disclose personal information if required to do so by law or in
                the good faith belief that such disclosure is reasonably
                necessary to respond to subpoenas, court orders, or other legal
                process(es). We may disclose personal information to law
                enforcement offices, third party rights owners, or others in the
                good faith belief that such disclosure is reasonably necessary
                to: enforce Our Terms of Use or Privacy Policy; respond to
                claims that an advertisement, posting or other content violates
                the rights of a third party; or protect the rights, property or
                personal safety of Our Users or the general public.
              </p>
            </li>
            <li>
              <strong>6. LINKS TO OTHER SITES</strong>
              <p> </p>
              <p>
                Our Website links to other websites that may collect personally
                identifiable information about You. The Website and Droom are
                not responsible for the privacy practices or the content of
                those linked websites nor for any third party to whom We
                transfer Your data in accordance with Our Privacy Policy. Any
                usage of such third-party website or availing any service or
                product through third party shall be at Your risk and the
                Company is not responsible for any loss/damage or otherwise.
              </p>

              <p>
                Further, We encourage You to read the privacy statements of all
                such sites as their policies may be materially different from
                this Privacy Policy. You are solely responsible for maintaining
                the secrecy of Your passwords, and Your Website membership
                account information. Please be very careful, responsible, and
                alert with this information, especially whenever You are online.
              </p>
            </li>
            <li>
              <strong>7. SECURITY PRECAUTIONS </strong>
              <p> </p>
              <p>
                Our Website has stringent security measures in place to protect
                the loss, misuse, and alteration of the information under Our
                control. Whenever You change or access Your account information,
                We offer the use of a secure server. Once Your information is in
                Our possession We adhere to strict security guidelines,
                protecting it against unauthorized access.
              </p>
            </li>

            <li>
              <strong>8. CHOICE/OPT-OUT</strong>
              <p> </p>
              <p>
                We provide all Users with the opportunity to opt-out of
                receiving non-essential (promotional, marketing-related)
                communications from us on behalf of Our partners, and from Us in
                general, after setting up an account. If You have any concern
                about privacy or grievances with the Website or any of its
                content or to certificate any abuse of laws or breach of the
                Privacy Policy and Terms of Use, please contact us{' '}
                <a href="mailto:support@vehiclehistory.in">
                  (support@vehiclehistory.in)
                </a>{' '}
                with a thorough description of such concerns/ issues faced by
                You and we will try to resolve the issue for You.
              </p>
              <p>
                If You want to remove Your contact information from all Website
                lists and newsletters, please mail us at{' '}
                <a href="mailto:support@vehiclehistory.in">
                  support@vehiclehistory.in
                </a>
              </p>
            </li>
            <li>
              <strong>9. DATA DELETION OF USERS</strong>
              <p> </p>
              <p>
                If any registered User requests their personal data/information
                or account to be deleted from the Website, we honour such
                requests and delete the said user data from our servers. The
                Users can send an email on the customer support id{' '}
                <a href="mailto:support@vehiclehistory.in">support@droom.in </a>{' '}
                or on{' '}
                <a href="mailto:legal.group1@droom.in">legal.group1@droom.in</a>{' '}
                for request of their User data from the Website and we will
                delete the Users data within 24 hours and intimate the User on
                their registered email on the action taken.
              </p>
            </li>
            <li>
              <strong>10. ADVERTISEMENTS ON THE WEBSITE</strong>
              <p> </p>
              <p>
                We use third-party advertising companies to serve ads when You
                visit Our Website. These companies may use information (not
                including Your name, address, email address, or telephone
                number) about Your visits to this and other websites in order to
                provide advertisements about goods and services of interest to
                You.
              </p>
            </li>

            <li>
              <strong>11. YOUR CONSENT</strong>
              <p> </p>
              <p>
                By using the Website and/ or by providing Your information, You
                consent to the collection and use of the information You
                disclose on the Website in accordance with this Privacy Policy,
                including but not limited to Your consent for sharing Your
                information as per this Privacy Policy.
              </p>

              <p>
                If We decide to change Our Privacy Policy, We will post those
                changes on this page so that You are always aware of what
                information We collect, how We use it, and under what
                circumstances We disclose it.
              </p>
            </li>

            <li>
              <strong>12. GRIEVANCE OFFICER</strong>
              <p> </p>
              <p>
                In accordance with Information Technology Act 2000 and rules
                made there under, in case you have any grievances about the use
                of this Website / any of the policies for use / availing the
                services on the Website, you may contact the grievance officer
                appointed by us. The name and contact details of the Grievance
                Officer are provided below:
              </p>
            </li>
          </ul>
        </section>
        <section className="section-wrapper">
          <p>
            {' '}
            <strong>
              <u>Grievance Redressal Officer:</u>
            </strong>
          </p>
          <address class="nospace">
            <p>Mr. Sunil Kumar</p>
            <p>
              Plot No. 77-A, Iffco Road, Sector 18, Gurgaon - 122015, Haryana
              -India.
            </p>
            <p>Time: Mon to Sat (10:00 to 19:00)</p>
            <p>
              Email: <a href="mailto:support@droom.in">support@droom.in</a>
            </p>
          </address>
        </section>
          <section className="section-wrapper">
            <p>
                <strong>
                  COMPLAINCE UNDER GDPR (FOR INTERNATIONAL TRANSACTION) (EU REGION) 
                </strong>
            </p>
          <ul style={{ listStyle: 'none', paddingLeft: '30px' }}>
            <p>
                We are committed to protecting the privacy of our users and ensuring compliance with applicable data protection laws, including the <strong>General Data Protection Regulation (GDPR) for Users located in the European Union (EU).</strong>
            </p>
            
              <p>
                  <strong>
                      GDPR Compliance
                  </strong>
              </p>
            <li style={{ listStyle: 'none', paddingLeft: '20px'}}>
              <p>
                  The <strong>General Data Protection Regulation (GDPR)</strong> applies to the collection and processing of personal data of individuals in the EU. In line with GDPR, we ensure that the collection, processing, and storage of Personally Identifiable Information (PII) comply with the following principles:
              </p>
              <p>
                  <strong>1. Lawfulness, Fairness, and Transparency:</strong> We only collect and process data for lawful purposes, transparently informing you about the use of your data.<br/>
                  <strong>2. Purpose Limitation:</strong> Data is collected solely for the purposes described in this website and will not be used for unrelated purposes without your explicit consent.<br/>
                  <strong>3. Data Minimization:</strong> We only collect the minimum amount of personal data required to provide our services effectively.<br/>
                  <strong>4. Data Quality:</strong> We take steps to ensure your data is accurate and up to date.<br/>
                  <strong>5. Limited Storage Period:</strong> Your personal data is retained only for as long as necessary for the stated purposes or as required by law.<br/>
                  <strong>6. Information Security:</strong> We employ technical and organizational measures to protect your data against unauthorized access, loss, or misuse.<br/>
                  <strong>7. Accountability:</strong> We maintain clear processes to demonstrate our compliance with GDPR requirements.
              </p>
            </li>
            <p>
                <strong>
                    Your Rights Under GDPR
                </strong>
                </p>
            <li style={{ listStyle: 'none', paddingLeft: '20px'}} >
              <p>
                If you are an individual in the EU, you have the following rights regarding your personal data:<br/>
                • Right to Be Informed <br/>
                • Right of Access <br/>
                • Right to Rectification <br/>
                • Right to Erasure <br/>
                • Right to Restrict Processing <br/>
                • Right to Data Portability 
              </p>
            </li>
            <p>
                <strong>
                    Collection of sensitive data
                </strong>
            </p>
            <li style={{ listStyle: 'none', paddingLeft: '20px'}} >
            <p>
                We do not collect or retain sensitive personal data relating to your health, ethnic origin, religious belief, or political conviction etc at this site. In the case where we do seek to collect other sensitive data, we will do so in strict compliance with the local data privacy law.
            </p>
            </li>
            <p>
                <strong>
                    Why do we collect your personal data and how do we use it?
                </strong>
            </p>
            <li style={{ listStyle: 'none', paddingLeft: '20px'}} >
              <p>
                We collect and use your personal data, in compliance with applicable laws and regulations based on one or many of the following legal basis according to contexts:<br/>
                • we have obtained your prior consents. Please note that for this specific legal basis, you have the right to withdraw your consents at any time.<br/>
                • the processing is necessary in connection with any services to be provided.<br/>
                • we have a legitimate interest in carrying out the processing and that legitimate interest is not overridden by your interests, fundamental rights, or freedoms.<br/>
                • we have to process your personal data to comply with applicable laws and regulations.
              </p>
            </li>
            <p>
                <strong>
                    How long do we keep your personal data?
                </strong>
            </p>
            <li style={{ listStyle: 'none', paddingLeft: '20px'}} >
              <p>
                  Your personal data are processed for the period necessary for the purposes for which they have been collected, to comply with legal and regulatory obligations and for the duration of any period necessary to establish, exercise or defend any legal rights.<br/>
                  In order to determine the most appropriate retention periods for your personal data, we have specifically considered the amount, nature and sensitivity of your personal data, the reasons for which we collected your personal data, the service you deserve and expect from us together with the applicable legal requirements.
              </p>
            </li>
            <p>
                <strong>
                    Protection of Children
                </strong>
            </p>
            <li style={{ listStyle: 'none', paddingLeft: '20px'}} >
              <p>
                  Droom does not intend to collect personally identifiable information from children (a child is defined as being under the age of 18) without permission of the parent or legal guardian. Children should NOT submit personally identifiable information to Droom without the explicit permission of their parent or legal guardian.<br/>
                  Where appropriate, we will instruct children not to submit personal data. If your child has submitted personal data and you would like to request that such information be removed, please contact us via our privacy mailbox <a href="mailto:support@droom.in">support@droom.in</a>. 
              </p>
            </li>
            <p>
                <strong>
                    Whom to Contact?
                </strong>
            </p>
            <li style={{ listStyle: 'none', paddingLeft: '20px'}} >
              <p>
                  Contact us if you Have any questions about this policy or other privacy notices on this website
                  Please reach us at <a href="mailto:support@droom.in">support@droom.in</a>.
              </p>
            </li>
            </ul>
        </section>
      </Container>
    </div>
  );
}
